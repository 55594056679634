
// import React, { useEffect, useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Button,
//   Typography,
//   Drawer,
//   IconButton,
//   List,
//   ListItem,
//   ListItemText,
// } from "@mui/material";
// import { border, Box, color, Container } from "@mui/system";
// import sevakilogo from "../assets/sevakilogo.png";
// import { Menu as MenuIcon } from "lucide-react";
// import useResponsive from "../hooks/useResponsive";

// const Navbar = () => {
//   const { isSmallScreen } = useResponsive();
//   const [drawerOpen, setDrawerOpen] = useState(false); // Manage drawer open/close state

//   const handleDrawerToggle = () => {
//     setDrawerOpen(!drawerOpen); // Toggle drawer visibility
//   };



//   const [navBackground, setNavBackground] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 50) {
//         setNavBackground(true);
//       } else {
//         setNavBackground(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
//   return (
//     <Box
//     className={`navbar ${navBackground ? "scrolled" : ""}`}
//     sx={{
//       backgroundColor: "rgba(0, 255, 255, 0)",
//       position: "relative", // Ensure proper layout structure
//     }}
//   >
//     <Container sx={{ width: "100%", maxWidth: "1400px" }}>
//       <AppBar
//         position="sticky"
//         sx={{
//           backgroundColor: "white",
//           boxShadow: "none",
//           padding: "10px 0",
//           backgroundColor: "inherit",
//           top: 0, // Ensures it sticks to the top
//           zIndex: 1100, // Ensures it stays above other elements
//         }}
//       >
//         <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
//           <Box sx={{ display: "flex", alignItems: "center" }}>
//             <img
//               src={sevakilogo}
//               alt="Logo"
//               style={{ height: "35px", marginRight: "10px" }}
//               loading="lazy"
//             />
//           </Box>

//           {/* Menu for small screen */}
//           {isSmallScreen ? (
//             <IconButton onClick={handleDrawerToggle} sx={{borderColor:"#fff" , color:"#fff"}}>
//                <MenuIcon sx={{ color: "#fff" }} />
//             </IconButton>
//           ) : (
//             <Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
//               {["Home", "Services", "Testimonials", "About Us", "Contact Us"].map(
//                 (item) => (
//                   <Typography
//                     key={item}
//                     sx={{
//                       color: "#BCBCBC",
//                       cursor: "pointer",
//                       fontSize: "14px",
//                     }}
//                   >
//                     {item}
//                   </Typography>
//                 )
//               )}
//               <Button
//                 sx={{
//                   color: "#ffffff",
//                   backgroundColor: "#7E60BF",
//                   borderRadius: "20px",
//                   padding: "8px 20px",
//                   fontSize: "14px",
//                   textTransform: "lowercase",
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//                 onClick={() =>
//                   window.open(
//                     "https://docs.google.com/forms/d/e/1FAIpQLSfvRfuimaRIE1Fz3Od7xUwciv3S1ZQJhJJgwF845s8Hlk-Xsg/viewform?usp=sharing",
//                     "_blank"
//                   )
//                 }
//               >
//                 <span style={{ textTransform: "uppercase" }}>B</span>
//                 <span>ook a Services</span>
//               </Button>
//             </Box>
//           )}
//         </Toolbar>
//       </AppBar>

//       {/* Drawer (Slide-in menu) */}
//       <Drawer
//         anchor="left"
//         open={drawerOpen}
//         onClose={handleDrawerToggle}
//         sx={{
//           width: 250,
//           flexShrink: 0,
//           "& .MuiDrawer-paper": {
//             width: 250,
//             boxSizing: "border-box",
//             backgroundColor: "#1E1E1E",
//             paddingTop: "20px",
//           },
//         }}
//       >
//         <Box sx={{ display: "flex", alignItems: "center", ml:2, mb:1 }}>
//             <img
//               src={sevakilogo}
//               alt="Logo"
//               style={{ height: "35px", marginRight: "10px" }}
//               loading="lazy"
//             />
//           </Box>
//         <List>
//           {["Home", "Services", "Testimonials", "About Us", "Contact Us"].map(
//             (text) => (
//               <ListItem button key={text}>
//                 <ListItemText
//                   primary={text}
//                   sx={{ fontSize: "16px", color: "#7E60BF" }}
//                 />
//               </ListItem>
//             )
//           )}
//         </List>
//         <Button
//                 sx={{
//                   color: "#ffffff",
//                   backgroundColor: "#7E60BF",
//                   borderRadius: "20px",
//                   padding: "8px 20px",
//                   fontSize: "14px",
//                   textTransform: "lowercase",
//                   display: "flex",
//                   alignItems: "center",
//                   width:'150px',
//                   ml:2
//                 }}
//                 onClick={() =>
//                   window.open(
//                     "https://docs.google.com/forms/d/e/1FAIpQLSfvRfuimaRIE1Fz3Od7xUwciv3S1ZQJhJJgwF845s8Hlk-Xsg/viewform?usp=sharing",
//                     "_blank"
//                   )
//                 }
//               >
//                 <span style={{ textTransform: "uppercase" }}>B</span>
//                 <span>ook a Services</span>
//               </Button>
//       </Drawer>
//     </Container>
//   </Box>

//   );
// };

// export default Navbar;



import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import sevakilogo from "../assets/sevakilogo.png";
import { Menu as MenuIcon } from "lucide-react";
import useResponsive from "../hooks/useResponsive";

const Navbar = () => {
  const { isSmallScreen } = useResponsive();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [navBackground, setNavBackground] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setNavBackground(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box className={`navbar ${navBackground ? "scrolled" : ""}`}>
      <Container sx={{ width: "100%", maxWidth: "1400px" }}>
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: "white",
            boxShadow: "none",
            padding: "10px 0",
            backgroundColor: "inherit",
            top: 0,
            zIndex: 1100,
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={sevakilogo} alt="Logo" style={{ height: "35px" }} loading="lazy" />
            </Box>

            {isSmallScreen ? (
              <IconButton onClick={handleDrawerToggle} sx={{ color: "#fff" }}>
                <MenuIcon sx={{ color: "#fff" }} />
              </IconButton>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
                <a href="#home" style={{ color: "#BCBCBC", cursor: "pointer", fontSize: "14px", textDecoration: "none" }}>Home</a>
                <a href="#services" style={{ color: "#BCBCBC", cursor: "pointer", fontSize: "14px", textDecoration: "none" }}>Services</a>
                <a href="#testimonials" style={{ color: "#BCBCBC", cursor: "pointer", fontSize: "14px", textDecoration: "none" }}>Testimonials</a>
                <a href="#about" style={{ color: "#BCBCBC", cursor: "pointer", fontSize: "14px", textDecoration: "none" }}>About Us</a>
                <a href="#contact" style={{ color: "#BCBCBC", cursor: "pointer", fontSize: "14px", textDecoration: "none" }}>Contact Us</a>
                <Button
                  sx={{
                    color: "#ffffff",
                    backgroundColor: "#7E60BF",
                    borderRadius: "20px",
                    padding: "8px 20px",
                    fontSize: "14px",
                    textTransform: "lowercase",
                  }}
                  onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfvRfuimaRIE1Fz3Od7xUwciv3S1ZQJhJJgwF845s8Hlk-Xsg/viewform?usp=sharing", "_blank")}
                >
                  <span style={{ textTransform: "uppercase" }}>B</span>
                  <span>ook a Service</span>
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{ "& .MuiDrawer-paper": { width: 250, backgroundColor: "#1E1E1E", paddingTop: "20px" } }}
        >
          <Box sx={{ display: "flex", alignItems: "center", ml: 2, mb: 1 }}>
            <img src={sevakilogo} alt="Logo" style={{ height: "35px" }} loading="lazy" />
          </Box>
          <List>
            <ListItem button component="a" href="#home"><ListItemText primary="Home" sx={{ color: "#7E60BF" }} /></ListItem>
            <ListItem button component="a" href="#services"><ListItemText primary="Services" sx={{ color: "#7E60BF" }} /></ListItem>
            <ListItem button component="a" href="#testimonials"><ListItemText primary="Testimonials" sx={{ color: "#7E60BF" }} /></ListItem>
            <ListItem button component="a" href="#about"><ListItemText primary="About Us" sx={{ color: "#7E60BF" }} /></ListItem>
            <ListItem button component="a" href="#contact"><ListItemText primary="Contact Us" sx={{ color: "#7E60BF" }} /></ListItem>
          </List>
          <Button
            sx={{
              color: "#ffffff",
              backgroundColor: "#7E60BF",
              borderRadius: "20px",
              padding: { xs: "6px 15px", sm: "8px 20px" },
              fontSize: { xs: "12px", sm: "14px" },
              width: { xs: "120px", sm: "150px" },
              ml: 2,
              textTransform: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSfvRfuimaRIE1Fz3Od7xUwciv3S1ZQJhJJgwF845s8Hlk-Xsg/viewform?usp=sharing",
                "_blank"
              )
            }
          >
            <span style={{ textTransform: "uppercase" }}>B</span>
            <span>ook a Service</span>
          </Button>

        </Drawer>
      </Container>
    </Box>
  );
};

export default Navbar;
