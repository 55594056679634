import '../styles/Hero2.css';
import iphone from "../assets/iphone.png"
import logo1 from "../assets/logo4.png"
import logo2 from "../assets/logo2.png"
import logo3 from "../assets/logo3.png"
import { Box, Typography, Grid, Card, CardMedia, Container } from "@mui/material";
import useResponsive from '../hooks/useResponsive';
function Hero2() {
  const logos = [logo1, logo2, logo3, logo1, logo2, logo3]
  const { isSmallScreen } = useResponsive()
  return (
    <>
      <Container maxWidth="lg" >
        {/* Clients Section */}
        <Grid container spacing={2} sx={{ textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* Text Section */}
          <Grid item xs={12} lg={7}>
            <Typography
              color="primary"
              gutterBottom
              sx={{ color: "#7E60BF" }}
            >
              <b>CLIENTS</b>
            </Typography>
            <Typography variant={isSmallScreen ? "h4" : "h2"} gutterBottom sx={{ fontWeight: 400 }}>
              TRUSTED BY HOMES & BUSINESSES ALIKE
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ maxWidth: 600, mb: 4 }}
            >
              Globally maintaining proof of collaboration and idea sharing after vital
              solution building. A user collaborative interface ensures lasting
              structure and professional customer service visionary workflow.
            </Typography>
          </Grid>

          {/* Logos Section */}
          <Grid item xs={12} lg={5} sx={{ position: "relative" }} >
            <Box className='lg' sx={{ width: "100%", height: "100%", position: "absolute", zIndex: 1 }}>

            </Box>
            <Box

              sx={{
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <div className="logo-slider">
                <div className="logo-track-reverse">
                  {logos.concat(logos).map((logo, index) => (
                    <Card key={index}  elevation={0}  sx={{ p: 2, textAlign: "center", mx: 2 }}>
                      <CardMedia component="img" image={logo}  alt={`Client Logo ${index + 1}`} sx={{ width: 180, objectFit: "contain" }} />
                    </Card>
                  ))}
                </div>
              </div>
            </Box>
            <Box sx={{ overflow: "hidden", position: "relative", width: "100%", }}>
              <div className="logo-slider">
                <div className="logo-track">
                  {logos.map((logo, index) => (
                    <Card
                      key={`original-${index}`}
                      elevation={0}
                      sx={{ p: 2, textAlign: "center", mx: 2 }}
                    >
                      <CardMedia component="img" image={logo} alt={`Client Logo ${index + 1}`}
                        sx={{ width: 180, objectFit: "contain", }}
                      />
                    </Card>
                  ))}
                  {/* Duplicate the logos without gaps */}
                  {logos.map((logo, index) => (
                    <Card key={`duplicate-${index}`} elevation={0} sx={{ p: 2, textAlign: "center", mx: 2 }} >
                      <CardMedia
                        component="img" image={logo} alt={`Client Logo Duplicate ${index + 1}`}
                        sx={{ width: 400, objectFit: "contain", maxWidth: 100 }}
                      />
                    </Card>
                  ))}
                </div>
              </div>
            </Box>

          </Grid>
        </Grid>

        {/* Mobile App Section */}
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: "center",
            textAlign: { xs: "center", md: "left" }, // Center text on small screens, left-aligned on larger screens
          }}
        >
          {/* Text Section */}
          <Grid  item xs={12} md={8} sx={{ height: { xs: "auto", lg: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" } }}>
            <Box sx={{}}>
              <Typography
                color="primary"
                gutterBottom
                sx={{ color: "#7E60BF" }} // Purple color for the heading
              >
                <b>SEVAKI APP</b>
              </Typography>
              <Typography variant={isSmallScreen ? "h4" : "h2"} gutterBottom sx={{ fontWeight: 400 }}>
                WILL BE LIVE SOON ON MOBILE APP! STAY TUNED..
              </Typography>
            </Box>
          </Grid>

          {/* Image Section */}
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: "center" }}>
              <img
                src={iphone} // Replace with the actual image URL or import statement
                alt="Mobile App Mockup"
                style={{
                  maxWidth: "100%", // Ensures image fits within its container
                  height: "auto", // Maintains aspect ratio
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        </Grid>

      </Container>
    </>
  );
}

export default Hero2;