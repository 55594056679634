import { Box } from '@mui/material';
import './App.css';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Hero2 from './components/Hero2';
import Hero3 from './components/Hero3';
import Navbar from './components/Navbar';
import Services from './components/Services';


import Testimonial from './components/Testimonial';


function App() {
  return (
    <div className="App">
      <Box
        sx={{
          backgroundColor: "rgba(0, 255, 255, 0)",
          position: "relative", // Ensure proper layout structure
        }}
      >
        <Box sx={{ width: "100%", position: "fixed", zIndex: 5 }}>
          <Navbar />
        </Box>
      </Box>
      <Hero />
      <Hero3 />
      <Services />
      <Testimonial />
      <Hero2 />
      <Footer />
    </div>
  );
}

export default App;
