import React, { useRef } from 'react';
import useResponsive from '../hooks/useResponsive';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Box, Typography, Avatar, Container } from "@mui/material";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import avatarImg from '../assets/Ellipse 6.png';

const Testimonial = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive();
    const swiperRef = useRef(null); 

    const testimonialsdata = [
        {
            name: "Akhil Akula",
            image: avatarImg,
            company: "Abilio It Solution",
            text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum  (The Extremes of Good and Evil) by Cicero.",
        },
        {
            name: " Natasha",
            image: avatarImg,
            company: "Abilio It Solution",
            text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum  (The Extremes of Good and Evil) by Cicero.",
        },
        {
            name: "Suman Reddy",
            image: avatarImg,
            company: "Sevaki Solutions",
            text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum  (The Extremes of Good and Evil) by Cicero.",
        },
        {
            name: "Suman Reddy",
            image: avatarImg,
            company: "Abilio It Solution",
            text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum  (The Extremes of Good and Evil) by Cicero.",
        },
    ]


    const goToNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext(); // Go to next slide
        }
    };

    const goToPrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev(); // Go to previous slide
        }
    };

    return (
        <Container id='testimonials'>
            <style>
                {`
          /* Hide the default arrows */
          .swiper-button-prev:after, 
          .swiper-button-next:after, 
          .swiper-rtl .swiper-button-prev:after, 
          .swiper-rtl .swiper-button-next:after {
            display: none !important;
          }
        `}
            </style>
            <Box sx={{ overflow: "hidden", marginTop: isSmallScreen ? "0px" : "30px", maxHeight: "500px", padding: "0px 10px", my: 5, display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <Box sx={{ width: isSmallScreen ? '100%' : '85%' }}>
                    <Swiper
                        ref={swiperRef} // Attach reference to the Swiper component
                        pauseOnMouseEnter={true}
                        breakpoints={{
                            430: { slidesPerView: 1, spaceBetween: 20 },
                            767: { slidesPerView: 1, spaceBetween: 20 },
                            1024: { slidesPerView: 1, spaceBetween: 20 },
                        }}
                        centeredSlides={true}
                        pagination={{ clickable: true }}
                        loop={true}
                        navigation={false} // Disable default navigation buttons
                        autoplay={true}
                        modules={[Autoplay, Pagination]}
                        style={{ maxHeight: "inherit" }}
                    >
                        {testimonialsdata.map((testimonial, index) => (
                            <SwiperSlide className="mySwiper-s" key={index} style={{ display: "flex", alignItems: 'center' }}>
                                <Box sx={{ display: "flex", alignItems: 'center', width: "100%" }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        border: "1px solid #eee",
                                        borderRadius: "8px",
                                        padding: isSmallScreen ? "0px" : isMediumScreen ? "7px" : "20px 15px",
                                        alignItems: "center",
                                        background: "rgba(255, 255, 255, 0.2)",
                                        backdropFilter: "blur(10px)",
                                        opacity: 0.8,
                                        width: "100%",
                                    }}>
                                        <Typography variant="body2" sx={{ color: "rgba(151, 166, 173, 0.8)", marginBottom: isSmallScreen ? "0px" : "12px", mt: isSmallScreen ? '20px' : '0', textAlign: "center" }}>
                                            {testimonial.text}
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', width: '100%' }}>
                                            <Avatar src={testimonial.image} sx={{ width: 46, height: 46, mb: 1 }} />
                                            <Typography variant="body1" sx={{ color: '#000000' }}>{testimonial.name}</Typography>
                                            <Typography variant="body2" sx={{ color: "rgba(151, 166, 173, 0.8)", mb: 2 }}>{testimonial.company}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* Custom navigation buttons */}
                    {/* <Box
                        className="swiper-button-prev"
                        sx={{
                            position: "absolute",
                            left: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",  // Dark background for better visibility
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            zIndex: 9999,
                        }}
                        onClick={goToPrev} // Attach click handler to go to previous slide
                    >
                        <ArrowBackIcon sx={{ color: "#ffffff", fontSize: "10px !important", width:'20px', cursor: "pointer" }} />
                    </Box>
                    <Box
                        className="swiper-button-next"
                        sx={{
                            position: "absolute",
                            right: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",  // Dark background for better visibility
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            zIndex: 9999,
                        }}
                        onClick={goToNext} // Attach click handler to go to next slide
                    >
                        <ArrowForwardIcon sx={{ color: "#ffffff", fontSize: "10px", width: "20px", cursor: "pointer" }} />
                    </Box> */}
                </Box>
            </Box>
        </Container>
    );
};

export default Testimonial;
