import React from 'react';
import { Box, Typography, Container, Grid, IconButton, Link, styled } from '@mui/material';
import { Instagram, Facebook, Twitter, Youtube } from 'lucide-react';

import logoImage from '../assets/sevakilogo.png';
import useResponsive from '../hooks/useResponsive';
// import { useNavigate } from 'react-router-dom';
const LogoImage = styled('img')({
    width: '80px',
    height: '50px',
});

const Footer = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive()
    // const navigate = useNavigate()

    // const handleNavigate = () => {
    //     navigate('/')
    // }
    return (
        <Box component="footer" sx={{
            backgroundColor: '#1E1E1E',
            padding: '20px 0',
            marginTop: 2,
            borderTop: '1px solid #e0e0e0',
        }}
        >
            <Container maxWidth="lg">
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', padding: '5px' }}>
                    <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 2 }} disableRipple disableFocusRipple>
                        <LogoImage
                        //  onClick={handleNavigate}
                          src={logoImage} alt="Sevaki Logo"
                            sx={{ width: '165px', height: '45px', marginLeft: isSmallScreen ? "0px" : '85px' }} />
                    </IconButton>
                </Box>
                <hr />
                <Grid container spacing={4} justifyContent="space-evenly" sx={{ marginTop: '0px' }} >
                    <Grid item xs={6} sm={4} md={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '20px' , color: '#FFFFFF'}}>  For Users </Typography>
                        <Box>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px', color: '#FFFFFF'}}>
                                <Link href="#/aboutus" color="inherit" underline="none">Services</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px', color: '#FFFFFF' }}>
                                <Link href="#/membership" color="inherit" underline="none">Tesimonials</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px', color: '#FFFFFF' }}>
                                <Link href="#" color="inherit" underline="none">About Us</Link>
                            </Typography>
                         
                            <Typography variant="body1" sx={{ fontSize: '13px', color: '#FFFFFF' }}>
                                <Link href="#/privacypolicy" color="inherit" underline="none">Contact Us</Link>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '20px', color: '#FFFFFF' }}> For Partners </Typography>
                        <Box sx={{ fontSize: '13px' }}>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' , color: '#FFFFFF'}}>
                                <Link href="#" color="inherit" underline="none">Register us</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '8px', fontSize: '13px' , color: '#FFFFFF'}}>
                                <Link href="#" color="inherit" underline="none">Training Center</Link>
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: '13px', color: '#FFFFFF' }}>
                                <Link href="#" color="inherit" underline="none">Careers</Link>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '20px', textAlign: 'center' , color: '#FFFFFF'}}>  Social Media</Typography>
                            <Box display="flex" gap={2}>
                                <IconButton
                                    href="#" 
                                    color="inherit" sx={{ color: '#ffffff' }} >
                                    <Instagram size={20} />
                                </IconButton>
                                <IconButton href="#" 
                                    sx={{ color: '#ffffff'  }}
                                >
                                    <Facebook size={20} />
                                </IconButton>
                                <IconButton href="#"
                                    sx={{  color: '#ffffff'  }}
                                >
                                    <Twitter size={20} />
                                </IconButton>
                                <IconButton href="#"
                                    sx={{  color: '#ffffff'  }}
                                >
                                    <Youtube size={20} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={4} md={3} textAlign={{ xs: 'center', md: 'left', marginBottom: isSmallScreen ? '15px' : '0px', }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '20px', color: '#FFFFFF' }}>
                            Download the app
                        </Typography>
                        <Box display="flex" gap={2} justifyContent={{ xs: 'center', md: 'left' }}>
                            <Link href="#">
                                <img
                                    loading="lazy"
                                    src="https://e7.pngegg.com/pngimages/918/845/png-clipart-google-play-logo-google-play-app-store-android-google-play-text-logo.png" 
                                    style={{ width: '120px', borderRadius:'5px' }}
                                />
                            </Link>
                            <Link href="#" >
                                <img
                                    loading="lazy"
                                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" 
                                     style={{ width: '110px' }}
                                />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <hr />

                <Box textAlign="center" mt={2}>
                    <Typography variant="body2" color="#ffffff" >
                        © Copyright 2024 <a href='#' style={{color:'#7E60BF',textDecoration:'underline'}}>Sevaki Technologies Pvt Ltd</a> All rights reserved.
                    </Typography>

                </Box>
            </Container>
        </Box>
    );
};

export default Footer;