import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";

import imageone from '../assets/imageone.png'
import imagetwo from '../assets/imagetwo.png'
import useResponsive from "../hooks/useResponsive";


const Hero3 = () => {
    const { isSmallScreen } = useResponsive();
    return (
        <Container id='about' sx={{paddingTop: isSmallScreen ? 5 : 33 }}>
            <Box sx={{ display: "flex", justifyContent: 'space-between', flexDirection: { xs: "column", md: "row" }, alignItems: "center", p: 5, gap: 4 }}>
                <Box sx={{ position: "relative" }}>
                    <Box sx={{ position: "relative", display: "inline-block" }}>
                        <Box
                            component="img"
                            src={imageone}
                            alt="Handshake"
                            width={350}
                            height={isSmallScreen?200:550}
                            sx={{ borderRadius: "12px", boxShadow: "0px 4px 10px rgba(0,0,0,0.1)" }}
                        />
                        <Box sx={{ position: "absolute", top: "50%", left: isSmallScreen?'75%':"95%", transform: "translate(-50%, -50%)" }}>
                            <Box
                                component="img"
                                src={imagetwo}
                                alt="Teamwork"
                                width={isSmallScreen?150:350}
                                height={isSmallScreen?150:400}
                                sx={{ borderRadius: "12px", boxShadow: "0px 4px 10px rgba(0,0,0,0.1)" }}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Right Section - Text */}
                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign:isSmallScreen?"center": 'right' }}>
                    <Typography color="#7E60BF" gutterBottom>
                        <b>ABOUT US</b>
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" sx={{ width: { xs: '350px', md: '430px' } }}>
                        MAKE YOUR CAR FEEL LIKE A BRAND NEW ONE
                    </Typography>
                    <Box sx={{ width: { xs: '100%', md: '430px' } }}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            gutterBottom
                        >
                            Globally maintain high payoff collaboration and idea sharing after viral solution leading are edge mindshare rather than premier testing pursue professional customer service revolutionary services...
                        </Typography>
                    </Box>
                    <Box>
                        <Button sx={{ mt: 2, borderRadius: "20px", textTransform: "none", color: "#fff", backgroundColor: '#7E60BF', p: 1, px:2 }}>
                            Get more info
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default Hero3;
