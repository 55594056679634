import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import banner from "../assets/Frame4.png";

import heroImg1 from '../assets/houseKeeper.png'
import heroImg2 from '../assets/carWash.png'
import heroImg3 from '../assets/electrician.png'
import heroImg4 from '../assets/plumber.png'
import heroImg5 from '../assets/heroMaid.png'
import heroImg6 from '../assets/stroller.png'
import useResponsive from "../hooks/useResponsive";

const services = [
  { icon: heroImg1, label: "Maids" },
  { icon: heroImg2, label: "Car Wash" },
  { icon: heroImg3, label: "Electrician" },
  { icon: heroImg4, label: "Plumber" },
  { icon: heroImg5, label: "Beautician" },
  { icon: heroImg6, label: "Care Takers" },
];

const Hero = () => {

  const { isSmallScreen } = useResponsive()
  return (
    <Box>
      {/* Hero Section */}
      <Box sx={{
          display: { xs: "flex", md: "flex" },
          alignItems: "center", justifyContent: "flex-end", position: "relative",
          height: { xs: "500px", sm: "450px", md: "450px", lg: "600px" },
          padding: "0 20px", backgroundImage: `url(${banner})`,
          backgroundSize: "cover",backgroundPosition: "center",
          backgroundRepeat: "no-repeat", textAlign: "right",
        }}
      >
        <Box sx={{ padding: isSmallScreen ? 0 : "20px", width: isSmallScreen ? '100%' : "50%", maxWidth: "600px" }}>
          <Typography variant="h4" fontWeight="bold" color="white"> Your one-stop solution for home services</Typography>
          <Typography variant="body1" mt={2} color="white">
            Sevaki brings trusted professionals right to your doorstep. Enjoy convenience, reliability, and top-quality service.
          </Typography>
        </Box>
      </Box>

      <Box sx={{ position: isSmallScreen ? 'static' : "absolute", bottom: isSmallScreen ? 200 : '-45%', left: 0, right: 0, zIndex: 1 }}>
        <Container  sx={{
            border: isSmallScreen ? 'none' : "1px solid grey",
            display: "flex",
            my: isSmallScreen ? 2 : 4,
            borderRadius: "10px",
            backgroundColor: "white",
            padding: "40px 20px",
          }}
        >
          <Grid container spacing={4}>
            {/* Left Section */}
            <Grid item lg={7} xs={12}>
              <Box sx={{ backgroundColor: "white", padding: "40px 20px", textAlign: "left" }}>
                <Typography variant="body2" color="#7E60BF" fontWeight="bold">   SERVICES</Typography>
                <Typography variant="h4" fontWeight="" mt={1}>EFFORTLESS HOME CARE, ANYTIME!</Typography>
                <Typography variant="body2" mt={2} maxWidth="800px">
                    Your trusted partner for hassle-free home maintenance! Sevaki offers reliable and professional services, including
                     maid booking, home cleaning, and car wash, ensuring your space stays spotless and stress-free. Book with 
                     ease and experience seamless service at your convenience.
                </Typography>
              </Box>
            </Grid>

            {/* Right Section */}
            <Grid item lg={5} xs={12}>
              <Grid container spacing={4} justifyContent="center" mt={2}>
                {services.map((service, index) => (
                  <Grid item lg={4} xs={6} sm={4} md={4}
                    key={index} sx={{ textAlign: "center"}}
                    onClick={() => window.open(
                        "https://docs.google.com/forms/d/e/1FAIpQLSfvRfuimaRIE1Fz3Od7xUwciv3S1ZQJhJJgwF845s8Hlk-Xsg/viewform?usp=sharing",
                    
                      )
                    }
                  >
                    <Box sx={{cursor:'pointer'}}>
                      <img src={service.icon} width={40} alt={service.label} />
                      <Typography variant="body2" fontWeight="bold" mt={1}>
                        {service.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
