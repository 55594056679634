
import { useTheme, useMediaQuery } from '@mui/material';

const useResponsive = () => {

    const theme = useTheme();

    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return { isSmallScreen, isMediumScreen, isExtraSmallScreen, isLargeScreen };

};

export default useResponsive; 