import { ArrowRight } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Container, styled, Typography } from '@mui/material'
import React from 'react'

import img1 from '../assets/Group 9.png'
import img2 from '../assets/Group 10.png'
import img3 from '../assets/Group 12.png'
import img4 from '../assets/Group 13.png'
import img5 from '../assets/Group 14.png'
import img6 from '../assets/Group 15.png'
import useResponsive from '../hooks/useResponsive';




const Services = () => {

    const { isSmallScreen, isLargeScreen, isMediumScreen } = useResponsive()

    const services = [
        {
            title: "Maid Services",
            description: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ",
            icon: img1,
        },
        {
            title: "Car Wash",
            description: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ",
            icon: img2,
        },
        {
            title: "Electrician",
            description: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ",
            icon: img3,
        },
        {
            title: "Plumber",
            description: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ",
            icon: img4,
        },
        {
            title: "Beautician",
            description: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ",
            icon: img5,
        },
        {
            title: "Care Takers",
            description: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. ",
            icon: img6,
        },
    ];


    const StyledCard = styled(Card)({
        backgroundColor: "#2A2A2A",
        boxShadow: "none",
        cursor: "pointer",
        marginBottom: "8px",
        transition: "background-color 0.3s ease, color 0.3s ease",
        width: "330px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    });
    return (
        <Box id='services' sx={{ backgroundColor: '#1E1E1E' }}>
            <Container sx={{ py: 2 }}>
                <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="body2" color="#7E60BF" mt={3}>Services</Typography>
                    <Typography variant="h4" color="white" my={2} width={isSmallScreen ? '100%' : '40%'}>REDEFINING CAR CARE
                        CONVENIENCE</Typography>
                    <Typography variant="body1" color="white">We bring top-notch car cleaning services straight to your parking spot. With a focus on quality, eco-friendly solutions, and customer <br /> convenience, we ensure your car shines without disrupting your day. Experience effortless car care with us!</Typography>
                </Box>
                <Box sx={{ textAlign: "center", padding: "24px 0" }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "3px 10px" }}>
                        {services.map((service, index) => (
                            <StyledCard
                                key={index}
                                elevation={3}
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                    window.open(
                                        "https://docs.google.com/forms/d/e/1FAIpQLSfvRfuimaRIE1Fz3Od7xUwciv3S1ZQJhJJgwF845s8Hlk-Xsg/viewform?usp=sharing",
                                        "_blank"
                                    )
                                }
                            >
                                <CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box
                                            component="img"
                                            src={service.icon}
                                            alt={service.title}
                                            loading="lazy"
                                            sx={{ width: 50, height: 50, marginRight: 2, mb: 1, mt: 3 }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="h6"
                                        sx={{ marginTop: 1, display: "flex", justifyContent: "start", lineHeight: "17px", mb: 1 }}
                                        className="title" color='white'
                                    >
                                        {service.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ marginTop: 1, textAlign: "left", lineHeight: "17px" }}
                                        className="description"
                                        color='#676767'
                                    >
                                        {service.description}
                                    </Typography>

                                </CardContent>
                            </StyledCard>
                        ))}
                    </Box>

                </Box>
            </Container>
        </Box>
    )
}

export default Services